import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Title from "../components/Title";

import Gallery from "../components/Gallery";

const GalleryPage = () => {
  const data = useStaticQuery(query).strapiGaleria.Element;
  const images = data.map(({ localFile }) => localFile.childImageSharp);
  return (
    <Layout pageTitle="Galeria">
      <Title>Galeria</Title>
      <Gallery images={images} />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiGaleria {
      Element {
        name
        width
        height
        localFile {
          childImageSharp {
            fullsize: gatsbyImageData(layout: FULL_WIDTH)
            thumbnail: gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              aspectRatio: 1
              width: 200
              quality: 50
            )
          }
        }
      }
    }
  }
`;

export default GalleryPage;
